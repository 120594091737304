<template>
  <span class="text-center align-center">
    {{ currentTime }} <i class="fas fa-clock" v-if="currentTime != null"></i>
  </span>
</template>

<script>
import moment from 'moment-timezone';
import { vue } from '@/main';

export default {
  name: 'clientTimeZone',
  data: () => ({
    interval:0,
    currentTime: null
  }),
  props: {
    country: {
      required: true,
      default: () => null
    },
    needChange: {
      required: false,
      default: () => false
    }
  },
  created() {
    this.initInterval();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {

  },
  computed: {
    allTimeZones() {
      return vue.$t(`timeZone`);
    }
  },
  methods: {
    initInterval() {
      this.interval = setInterval(this.getTime, 1000);
    },
    getTime() {
      if(this.country === null || this.country.trim() === ''){
        this.currentTime = null;
        return
      }
      if(this.allTimeZones[this.country] == null){
        this.currentTime = null;
        console.warn('Timezone not found for', this.country);
        clearInterval(this.interval);
        return;
      }
      const currentTime = moment()
          .tz(this.allTimeZones[this.country]);
      //this.currentTime = this.country + ' - ' + currentTime.format('HH:mm');
      this.currentTime = currentTime.format('HH:mm');
    },
  }
};
</script>

<style scoped>

</style>
