export function getDefaultFilter() {
  return ['clientCell', 'fullName', 'phone', 'mobile', 'email', 'country', 'statusId', 'marginCall', 'lastNote', 'account.balance']
}

export function getClientFields() {
  return [
    'id', 'clientCell',

    /*Details fields*/
    'firstName', 'lastName', 'fullName', 'phone', 'mobile', 'country','countryTime', 'city', 'address', 'zip', 'lang', 'email', 'birthday', 'gender', 'companyClient',

    //Manager fields
    'orgId', 'businessUnitId', 'creationTime', 'interestTime', 'ftdOwnerId', 'ftdTime','ftdAmount','stdOwnerId', 'stdTime', 'statusId',

    //Affiliate fields
    'affiliateId', 'campaign', 'banner', 'tag',

    //Representative fields
     'representativeUserId', 'lastAssignationTime',

    //Company fields
    'companyNumber', 'activity', 'position', 'companyName',

    'comment', 'active', 'lastNote', 'marginCall', 'account.balance', 'account.credit', 'hasAccounts',
    'monetary.totalDeposits', 'monetary.totalWithdrawals',
  ]
}
