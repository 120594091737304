import { vue } from '@/main';

export default function () {
  return [
    {
      key: 'creationTime',
      type: 'dateRange',
      props: { mode: 'dateTime' }
    }, { key: 'id' },
    { key: 'firstName' }, { key: 'lastName' }, { key: 'phone' }, { key: 'email' }, { key: 'account' }, { key: 'banner' },
    {
      key: 'statusList',
      type: 'select',
      props: {
        options: vue.$store.getters['clients/allStatuses'].map(status => ({
          label: status.status,
          value: status.id
        })),
        label: 'label',
        reduce: status => status.value,
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'buTypes',
      label: 'departments',
      type: 'department',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'buIds',
      label: 'businessUnit',
      type: 'bu',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'orgIds',
      type: 'org',
      props: {
        clearable: true,
        multiple: true
      }
    }, {
      key: 'affiliateId',
      type: 'affiliate',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'representativeUserIds',
      type: 'user',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'online',
      type: 'checkbox'
    },
    {
      key: 'marginCall',
      type: 'checkbox'
    }
  ];
}
