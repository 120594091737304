<template>
  <div v-bind:key="viewKey">
    <!--Breadcrumb button-->
    <breadcrumb-button hide-settings>
      <template #beforeDropdown>
        <div>
          <b-button variant="success" class="btn-icon" v-b-tooltip v-bind:title="$t('operations.add')"
                    @click="onClientSelected(null)"
          >
            <i class="fa-solid fa-user-plus"></i>
          </b-button>
          <b-button variant="primary" class="btn-icon mx-1" v-b-tooltip v-bind:title="$t('columns.self')"
                    @click="openColumnsModal">
            <i class="fa-solid fa-bars"></i>
          </b-button>
        </div>
      </template>
    </breadcrumb-button>
    <!--Filters-->
    <filter-card :filter-key="viewKey" v-model="filter" v-bind:fields="filterFields"
                 v-bind:hidden-fields="{'buTypes': viewKey!=='clients' }" search
                 file-export @export="createExcelFile" file-import @import="showImportExcelModal"
    />
    <!--Table Card-->
    <b-card no-body>

      <!--      <b-card-header class="pb-50">
              <h5>{{$t("menu.clients")}}</h5>
              <b-button @click="createExcelFile" class="d-inline-block" size="sm" variant="outline-success">
                Export this page to excel
              </b-button>
            </b-card-header>-->
      <b-card-body>
        <!--Table-->
        <clients-table ref="clientTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="true"
                       @selected="onClientSelected" :lazy="true" :busy="loading"
        >
          <!-- 3 buttons to change busUnit, status, representative -->
          <template #table-top-actions="{selectedIds}">
            <div class="w-100 mb-1" v-if="Object.keys(selectedIds).length > 0">
              <!--              {{selectedIds}}-->
              <b-button size="sm" id="set-representative" class="mr-1 d-inline-block client-action-button"
                        variant="outline-success"
              >
                <i class="fa-solid fa-user-tie"></i>
                <span class="description">{{ $t('operations.setRepresentative') }}</span>
              </b-button>
              <b-button size="sm" id="change-status" class="mr-1 d-inline-block client-action-button"
                        variant="outline-info"
              >
                <i class="fa-solid fa-user-tag"></i>
                <span class="description">{{ $t('operations.changeStatus') }}</span>

              </b-button>
              <b-button size="sm" id="change-business-unit" class="mr-1 d-inline-block client-action-button"
                        variant="outline-warning"
              >
                <i class="fa-solid fa-building-circle-arrow-right"></i>
                <span class="description">{{ $t('operations.changeBusinessUnit') }}</span>
              </b-button>

              <b-button size="sm" id="set-representative-random" class="mr-1 d-inline-block" style="width: 50px"
                        variant="outline-light" v-b-tooltip :title="$t('operations.setRandomRepresentative')"

              >
                <i class="fa-solid fa-user">?</i>
              </b-button>

              <!-- change-business-unit -->
              <b-popover
                  target="change-business-unit"
                  triggers="click"
                  :show.sync="popoverShowBU"
                  placement="bottomright"
                  custom-class="no-arrow"
                  @show="onShow"
              >
                <template #title>
                  <div style="display: flex">
                    <div style="width: 100%; flex: 2">{{ $t('operations.changeBusinessUnit') }}</div>
                    <div style="text-align: right; width: 100%; flex: 1">
                      <b-button size="sm" @click="onClose" class="" style="display: inline-block;padding: 5px;">
                        <i class="fa-solid fa-x" style="font-size: 12px !important;"></i>
                      </b-button>
                    </div>
                  </div>

                </template>

                <div style="width:300px;height:100px">
                  <business-unit-select-box v-model="optionSelection"/>
                  <div class="text-center mt-2">
                    <b-button @click="onOkBU" size="sm" variant="primary" class="btn d-inline-block btn-sm">Ok
                    </b-button>
                  </div>
                </div>
              </b-popover>

              <!--change-randomRep -->
              <b-popover
                  target="set-representative-random"
                  triggers="click"
                  :show.sync="popoverShowRepresentativeRandom"
                  placement="bottomright"
                  custom-class="no-arrow"
                  @show="onShow"
              >

                <template #title>
                  <div style="display: flex">
                    <div style="width: 100%; flex: 2">{{ $t('operations.setRandomRepresentative') }}</div>
                    <div style="text-align: right; width: 100%; flex: 1">
                      <b-button size="sm" @click="onClose" class="" style="display: inline-block;padding: 5px;">
                        <i class="fa-solid fa-x" style="font-size: 12px !important;"></i>
                      </b-button>
                    </div>
                  </div>

                </template>

                <div style="width:auto;height:100px">
                  <user-select-box :multi="true" v-model="optionSelectionMulti"/>
                  <div class="text-center mt-2">
                    <b-button @click="onOkRepresentativeRandom" size="sm" variant="primary">Ok</b-button>
                  </div>
                </div>
              </b-popover>
              <!-- change-Status -->
              <b-popover
                  target="change-status"
                  triggers="click"
                  :show.sync="popoverShowStatus"
                  placement="bottomright"
                  custom-class="no-arrow"
                  @show="onShow"
              >

                <template #title>
                  <div style="display: flex">
                    <div style="width: 100%; flex: 2">{{ $t('operations.changeStatus') }}</div>
                    <div style="text-align: right; width: 100%; flex: 1">
                      <b-button size="sm" @click="onClose" class="" style="display: inline-block;padding: 5px;">
                        <i class="fa-solid fa-x"></i>
                      </b-button>
                    </div>
                  </div>
                </template>

                <div style="width:300px;height:100px">
                  <client-status-picker v-model="optionSelection"/>
                  <div class="text-center mt-2">

                    <b-button @click="onOkStatus" size="sm" variant="primary">Ok</b-button>
                  </div>
                </div>
              </b-popover>
              <!-- set-representative -->
              <b-popover
                  target="set-representative"
                  triggers="click"
                  :show.sync="popoverShowRepresentative"
                  placement="bottomright"
                  custom-class="no-arrow"
                  @show="onShow"
              >

                <template #title>
                  <div style="display: flex">
                    <div style="width: 100%; flex: 2">{{ $t('operations.setRepresentative') }}</div>
                    <div style="text-align: right; width: 100%; flex: 1">
                      <b-button size="sm" @click="onClose" class="" style="display: inline-block;padding: 5px;">
                        <i class="fa-solid fa-x" style="font-size: 12px !important;"></i>
                      </b-button>
                    </div>
                  </div>

                </template>

                <div style="width:300px;height:100px">
                  <user-select-box v-model="optionSelection"/>
                  <div class="text-center mt-2">
                    <b-button @click="onOkRepresentative" size="sm" variant="primary">Ok</b-button>
                  </div>
                </div>
              </b-popover>

            </div>
          </template>
        </clients-table>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="clients" v-model="columns"
                   v-bind:columns-names="columnsNames" v-bind:columns-default="columnsDefaultNames"
    />
    <b-modal ref="fileUploadModal" title="Import Excel File" :ok-disabled="!isFormValid"
             @ok="importExcelFile" no-close-on-backdrop ok-variant="success" cancel-variant="danger"
    >
      <b-row>
      <b-col cols="12">
        <b-button style="float: right" variant="primary" size="sm" @click="downloadExcelTemplate">{{ $t('operations.downloadExcelTemplate') }}</b-button>
      </b-col>
      <b-col cols="12">
        <span style="font-size: 12px">{{ $t('operations.pickBusinessUnit') }}</span>
        <business-unit-select-box class="small" v-model="selectedBranchExcel"/>
      </b-col>
      <b-col cols="12" class="mt-3">
        <b-form-file v-model="file" accept=".xls,.xlsx"></b-form-file>
      </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import ClientsTable from '@/components/tables/ClientsTable';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import ClientSearchRequest from '@/model/client/ClientSearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import ClientModal from '@/components/modal/clientModal';
import FilterCard from '@/components/widget/FilterCard';
import ClientFilterFields from '@/model/filter/ClientFilterFields';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import ClientStatusPicker from '@/components/widget/picker/clientStatusPicker';
import UserPicker from '@/components/widget/picker/userPicker';
import { getClientFields, getDefaultFilter } from '@/model/client/ClientColumns';
import mixinPopover from '@/components/mixin/mixinPopover';
import UserSelectBox from '@/components/widget/select/UserSelectBox';
import mixinNotifications from '@/components/mixin/mixinNotifications';

export default {
  name: 'ClientsView',
  components: {
    UserSelectBox,
    ClientStatusPicker,
    UserPicker,
    BusinessUnitSelectBox,
    FilterCard,
    ClientModal,
    ColumnsModal,
    BreadcrumbButton,
    ClientsTable
  },

  mixins: [mixinBase, mixinPermissions, mixinPopover,mixinNotifications],
  data() {
    return {
      loading: true,
      optionSelection: '',
      optionSelectionMulti: null,
      columnsNames: getClientFields(),
      columnsDefaultNames: getDefaultFilter(),
      filter: ClientSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: [],
      popoverShowBU: false,
      popoverShowStatus: false,
      popoverShowRepresentative: false,
      popoverShowRepresentativeRandom: false,
      selectedBranchExcel: null,
      options: [
        {
          text: 'Option 1',
          value: 'option1'
        },
        {
          text: 'Option 2',
          value: 'option2'
        },
        {
          text: 'Option 3',
          value: 'option3'
        },
      ],
      file: null,
    };
  },
  async created() {
    await this.refreshData();
    let filter = ClientFilterFields();
    if (this.$route.meta.departments) {
      this.filter.buTypes = this.$route.meta.departments;
    }else this.filter.buTypes = [];
    this.filterFields = filter;
    this.loading = false;

  },

  computed: {
    ...mapGetters('data', ['allOrganizations']),
    viewKey() {
      return this.$route.name || 'clients';
    },
    isFormValid() {
      return this.selectedOption !== null && this.file !== null;
    },
  },
  methods: {
    ...mapActions('clients', ['refreshData', 'updateBusinessUnitOfClient',
      'updateStatusOfClient', 'updateRepresentativeOfClient', 'updateToRandomRep', 'importClientDataExcel']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    onClientSelected(selectedId) {
      //console.log("onClientSelected", selectedId)
      this.showClient(selectedId);
    },
    async updateBusinessUnitIdOfClient() {
      let $this = this;
      let loadingToken = this.$refs['clientTable'].setLoading();

      let cs = this.$refs['clientTable'].getAllSelected();
      let bu = {
        clientsId: Object.keys(cs),
        newBusiUnit: this.optionSelection
      };
      let ans = await this.updateBusinessUnitOfClient(bu);
      this.$refs['clientTable'].setLoaded(loadingToken);
      $this.refreshTables();
    },
    updateRepresentativeClient() {
      let $this = this;
      let loadingToken = this.$refs['clientTable'].setLoading();

      let cs = this.$refs['clientTable'].getAllSelected();
      let body = {
        clientsId: Object.keys(cs),
        newRepresentative: this.optionSelection
      };
      this.updateRepresentativeOfClient(body)
          .then(() => {
                console.log('after the update RepresentativeOfClient');
                this.$refs['clientTable'].setLoaded(loadingToken);
                $this.refreshTables();
              }, err => {
                console.log('there are error in updating the representative in viewFile', err);
              }
          );
    },
    updateStatusClient() {
      let $this = this;
      let loadingToken = this.$refs['clientTable'].setLoading();

      let cs = this.$refs['clientTable'].getAllSelected();
      let statusClient = {
        clientsId: Object.keys(cs),
        newStatus: this.optionSelection
      };
      console.log('client status', statusClient);
      this.updateStatusOfClient(statusClient)
          .then(() => {
                console.log('after the update StatusOfClient');
                this.$refs['clientTable'].setLoaded(loadingToken);
                $this.refreshTables();
              }
          );
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      this.optionSelection = '';
      this.closeAllPopovers();
    },
    onClose() {
      this.popoverShowBU = false;
      this.popoverShowStatus = false;
      this.popoverShowRepresentative = false;
      this.popoverShowRepresentativeRandom = false;
    },

    onOkBU() {
      if (this.optionSelection === '') {
        console.log('the business unit is empty');
        this.onClose();
      }

      this.updateBusinessUnitIdOfClient();
      this.optionSelection = '';
      this.onClose();
    },
    onOkStatus() {
      if (this.optionSelection === '') {
        console.log('the status is empty');
        this.onClose();
      }

      this.updateStatusClient();
      this.optionSelection = '';
      this.onClose();
    },
    onOkRepresentative() {
      if (this.optionSelection === '') {
        console.log('the representative is empty');
        this.onClose();
      }

      this.updateRepresentativeClient();
      this.optionSelection = '';
      this.onClose();
    },
    onOkRepresentativeRandom() {
      if (this.optionSelectionMulti == null) {
        console.log('the representative is empty');
        this.onClose();
      }

      this.updateRepresentativeClientRandomly();
      this.optionSelectionMulti = null;
      this.onClose();

    },
    updateRepresentativeClientRandomly() {
      let $this = this;
      let loadingToken = this.$refs['clientTable'].setLoading();

      let cs = this.$refs['clientTable'].getAllSelected();
      let userRepRequest = {
        clientsId: Object.keys(cs),
        repIds: this.optionSelectionMulti
      };
      console.log('user rep reqest', userRepRequest);
      this.updateToRandomRep(userRepRequest)
          .then(() => {
                console.log('after the update RepresentativeOfClient');
                this.$refs['clientTable'].setLoaded(loadingToken);
                $this.refreshTables();
              }, err => {
                console.log('there are error in updating the representative in viewFile', err);
              }
          );
    },
    createExcelFile() {
      this.$refs['clientTable'].createExcelFile();
    },
    showImportExcelModal() {
      this.selectedBranchExcel = null;
      this.$refs.fileUploadModal.show();
    },
    downloadExcelTemplateTemplate() {

    },
    downloadExcelTemplate() {
      // Specify the path to your Excel file in the public directory
      const fileUrl = '/clientTemplate.xlsx';
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'clientTemplate.xlsx'; // Optional: specify the download file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async importExcelFile() {
      let $this = this;
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('table', 'Client');

      // Add any additional params if needed
      const params = { 'branchId': this.selectedBranchExcel }; // Add any params here
      formData.append('params', btoa(JSON.stringify(params || {}))); // Encode params to base64

      try {
        let ans = await this.importClientDataExcel({ formData: formData });
        this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', {system:$this.$t('menu.clients')}));
        this.refreshTables();

      } catch (err) {
        this.showServerErrorNotification($this.$t('addDataError', {system:$this.$t('menu.clients'),error:err}));

      }

      this.$refs.fileUploadModal.hide();
      this.refreshTables();

    },

  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}


.client-action-button {
  width: 40px;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.5s ease-out;
}

.client-action-button .description {
  visibility: hidden;
}

.client-action-button:hover {
  width: 200px;
}

.client-action-button:hover .description {
  visibility: visible;
}

.popover.no-arrow > .arrow {
  display: none;
}

</style>
